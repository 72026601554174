<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Events</span>
        <v-spacer></v-spacer>
        <v-btn class="mt-3" color="primary" @click="openCreateModal">
          <v-icon>mdi-plus</v-icon>
          <span class="ml-2">Create</span>
        </v-btn>
      </v-card-title>

      <DataTableHeader :headers="headers" showSearchBox @update:search="searchText = $event" />
      <v-data-table data-has-actions="true" :headers="$filterHeaders(headers)" :items="eventList" sort-by="calories" :search="searchText">
        <template v-slot:item.id="{ index }">
          <span>{{ index + 1 }}</span>
        </template>
        <template v-slot:item.techAreasText="{ item }">
          <v-chip v-for="techArea in item.techAreasText" :key="techArea" small>
            {{ techArea }}
          </v-chip>
        </template>
        <template v-slot:item.type="{ item }">
          <span>{{ eventTypesObject[item.type] }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="gray" @click.stop="editItem(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.actions2="{ item }">
          <v-btn icon color="gray" class="mx-auto" @click.stop="deleteItem(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import CreateEventModal from '@/components/event/CreateEventModal';
import DataTableHeader from '@/components/ui/DataTableHeader';
import DataTableHeaderMixin from '@/mixins/DataTableHeader';
import { DEFAULT_DATE_FORMAT } from '@/helpers/constants';

export default {
  name: 'EventList',
  components: { DataTableHeader },
  data: () => ({
    headers: [
      { text: 'Edit', value: 'actions', sortable: false, width: '30px', align: 'center' },
      { text: 'Name', value: 'name' },
      { text: 'City', value: 'location', hideable: true, show: true },
      { text: 'Date', value: 'formattedDate', hideable: true, show: true },
      { text: 'Inn. Areas', value: 'techAreasText', hideable: true, show: true },
      { text: 'Type', value: 'type', hideable: true, show: true },
      { text: 'Updated Date', value: 'updatedAt', hideable: true, show: false },
      { text: 'Actions', value: 'actions2', sortable: false, width: '30px', align: 'center' },
    ],
    searchText: '',
  }),
  computed: {
    ...mapState(['events', 'eventTypes']),
    eventList() {
      return this.events.map((event) => {
        const date = dayjs(event.date).format(DEFAULT_DATE_FORMAT);
        return {
          ...event,
          formattedDate: date,
          techAreasText: event.techAreas.map((i) => i.name),
          type: event.type,
          updatedAt: dayjs(event.updated_at).format(DEFAULT_DATE_FORMAT),
        };
      });
    },
    eventTypesObject() {
      const eventType = {};
      this.eventTypes.forEach((t) => {
        eventType[t.id] = t.name;
      });
      return eventType;
    },
  },
  created() {
    this.fetchEvents();
  },
  methods: {
    ...mapActions(['fetchEvents', 'deleteEvent']),
    openCreateModal() {
      this.$showModal(CreateEventModal);
    },
    editItem(item) {
      this.$showModal(CreateEventModal, { event: item });
    },
    deleteItem(item) {
      const modal = this.$showConfirmModal(null, {
        confirm: () => {
          this.deleteEvent(item.id);
          modal.close();
        },
      });
    },
  },
  mixins: [DataTableHeaderMixin],
};
</script>
